* {
	font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
		Helvetica Neue, Arial, sans-serif;
	margin: 0%;
	padding: 0%;
	box-sizing: border-box;
	border: none;
}
.registration-wrapper {
	min-height: 100vh;
	background-color: #1890ff;
	display: flex;
	justify-content: center;
	place-items: center;
}

.left-bg-color {
	height: 100vh;
	width: 100%;
	clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%);
	background-color: #fff;
	position: absolute;
	left: 0%;
}
.logo__register {
	font-size: 40px;
	font-weight: bold;
	color: #1890ff;
	position: absolute;
	left: 5%;
	top: 3%;
}
@media only screen and (max-width: 900px) {
	.logo__register {
		font-size: 25px;
	}
}
.dialogue {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 35%;
	left: 12%;
	font-size: 25px;
}
@media only screen and (max-width: 900px) {
	.dialogue {
		top: 10%;
		left: 5%;
		font-size: 15px;
	}
}
.form-container {
	height: 310px;
	width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
	border: 1px solid #1890ff;
	position: absolute;
	right: 12%;
	margin-top: -65px;
}
@media only screen and (max-width: 900px) {
	.form-container {
		top: 20%;
		left: 5%;
		width: 325px;
		height: 310px;
		margin-top: 100px;
	}
}
.register-input-field {
	height: 50px;
	width: 350px;
	border-radius: 5px;
	border: 1px solid #d5dbe0;
	box-shadow: 5px 5px 20px 1px #eeeff0;
	padding: 0px 15px;
	margin: 5px 0;
}
@media only screen and (max-width: 900px) {
	.register-input-field {
		width: 300px;
	}
}
.btn-log-in {
	height: 50px;
	width: 350px;
	border-radius: 5px;
	background-color: #1890ff;
	font-size: 17px;
	color: #fff;
	cursor: pointer;
	margin-top: 10px;
}

.btn-log-in-processing {
	height: 50px;
	width: 300px;
	border-radius: 5px;
	background-color: #5968ba;
	font-size: 17px;
	color: #fff;
	cursor: progress;
	margin-top: 10px;
}

@media only screen and (max-width: 900px) {
	.btn-log-in {
		width: 300px;
	}
}
.link {
	color: #1890ff;
	display: flex;
	justify-content: center;
	padding-top: 10px;
	text-decoration: none;
}

.link:hover {
	text-decoration: underline;
}

.agrement {
	display: flex;
	justify-content: flex-start;
	text-decoration: none !important;
}

.agrement span {
	font-size: 12px;
	color: #757575;
}

.footer-link {
	position: absolute;
	bottom: 0%;
	left: 5%;
	text-decoration: none;
	color: #fff;
}
.footer-link:visited {
	color: #fff;
}

.start-for-free-registration {
	height: 35px;
	width: 215px;
	border-radius: 5px;
	background-color: #42b72a;
	color: #fff;
	font-size: 17px;
	position: absolute;
	top: 46%;
	left: 12%;
}

.pointer {
	cursor: pointer;
}

.underline {
	/* text-decoration: underline; */
	color: #1890ff !important;
}

.privecy__policy {
	color: #757575;
}

.privecy__policy .title {
	font-size: 20px;
}
.privecy__policy ul {
	/* list-style: none; */
	padding-left: 20px;
}
.privecy__policy ul li .circle {
	width: 10px;
	height: 5px;
	background: #757575;
	color: #757575;
}

.red {
	color: #ff0000;
}
