
/* custom scrollbar starts */

/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(224, 222, 222);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5eb0fd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}

/* custom scrollbar ends */

