@import "~antd/dist/antd.css";
* {
  margin: 0;
}

.layout {
  display: flex;
}
.main-content {
  background-color: #fdf9f9;
  width: 84%;
  margin: 5px;
  position: absolute;
  left: 200px;
}
@media only screen and (max-width: 600px) {
  .main-content {
    width: 100%;
    left: 0%;
    position: unset;
    margin: 0%;
  }
}
/* Handle sidebar open close */

.sidebar {
  position: absolute;
  left: -200px;
  z-index: 1;
  transition: ease-in-out 200ms;
}

.sidebar.open {
  left: 0px;
}
.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 1;
}
@media only screen and (min-width: 600px) {
  .overlay {
    display: none;
  }
}
.overlay.visible {
  display: none;
}
