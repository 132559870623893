.login__wrapper {
	min-height: 100vh;
	background-color: #1890ff;
	display: flex;
	justify-content: center;
	place-items: center;
}

.left-bg-color {
	height: 100vh;
	width: 100%;
	clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%);
	background-color: #fff;
	position: absolute;
	left: 0%;
}
.logo__login {
	font-size: 40px;
	font-weight: bold;
	color: #1890ff;
	position: absolute;
	left: 5%;
	top: 3%;
}
@media only screen and (max-width: 900px) {
	.logo__login {
		font-size: 25px;
	}
}
.dialogue {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 35%;
	left: 12%;
	font-size: 25px;
}
@media only screen and (max-width: 900px) {
	.dialogue {
		top: 20%;
		left: 5%;
		font-size: 15px;
	}
}
.start-for-free {
	height: 50px;
	width: 170px;
	border-radius: 5px;
	background-color: #42b72a;
	color: #fff;
	font-size: 17px;
	position: absolute;
	top: 46%;
	left: 12%;
}
@media only screen and (max-width: 900px) {
	.start-for-free {
		top: 27%;
		left: 5%;
	}
}
.login__form-container {
	height: 310px;
	width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
	border: 1px solid #1890ff;
	position: absolute;
	top: 30%;
	right: 12%;
}
.login-input-field {
	height: 50px;
	width: 350px;
	border-radius: 5px;
	border: 1px solid #d5dbe0;
	box-shadow: 5px 5px 20px 1px #eeeff0;
	padding: 0px 15px;
	margin: 8px 0;
}
@media only screen and (max-width: 900px) {
	.login-input-field {
		width: 300px;
	}
}
.btn-log-in {
	height: 50px;
	width: 350px;
	border-radius: 5px;
	background-color: #1890ff;
	font-size: 17px;
	color: #fff;
	cursor: pointer;
}
@media only screen and (max-width: 900px) {
	.btn-log-in {
		width: 300px;
	}
}
.link {
	color: #1890ff;
	display: flex;
	justify-content: center;
	padding-top: 10px;
	text-decoration: none;
}
.link:hover {
	text-decoration: underline;
}

.__free_account_mobile_button__container {
	display: none;
}
@media only screen and (max-width: 900px) {
	.login__form-container {
		top: 40%;
		left: 5%;
		width: 325px;
	}

	.__free_account_mobile_button__container {
		display: block;
	}
}
.footer-link {
	position: absolute;
	bottom: 0%;
	left: 5%;
	text-decoration: none;
	color: #fff;
}
.footer-link:visited {
	color: #fff;
}

.login__access {
	width: 100%;
	padding: 5px 0px;
	text-align: center;
}

.login__access button {
	color: #fff;
	padding: 2px 15px;
	margin: 5px;
	cursor: pointer;
	border-radius: 5px;
}

.login__access button.fill {
	background: #1890ff;
	border: 1px solid #1890ff;
}

.login__access button.outline {
	border: 1px solid #1890ff;
	color: #1890ff;
}

.__custom_btn_login_wrapper {
	text-align: center;
	margin-top: 10px;
}

.__custom_btn_login_wrapper button {
	padding: 5px 10px;
	margin: 5px;
	cursor: pointer;
	background: #4052b5;
	border-radius: 5px;
	color: #fff;
}

.__free_account_mobile_button {
	height: 35px;
	width: 215px;
	border-radius: 5px;
	background-color: #42b72a;
	color: #fff;
	font-size: 17px;
	margin-top: 28px;
	display: "none";
}
