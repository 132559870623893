.header {
  height: 40px;
  width: 100%;
  background: #fff;
  border: 1px solid #ececec;
  color: #1890ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  left: 3%;
}
.user {
  position: absolute;
  right: 3%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;

}
.pop-confirm {
  margin-left: 45px;
}
.open-icon {
  color: #1890ff;
  font-size: 25px;
  position: absolute;
  right: 3%;
}
@media only screen and (min-width: 601px) {
  .open-icon{
      display: none;
  }  
}